import Contacthero from "../component/Contacthero";
import './Contact.css';
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

function Contact(){


    return(
        <div >
            <div className="flex flex-col overflow-hidden">
                <div>
                <Contacthero/>
                </div>
                <div className="flex m-[5%] flex-wrap container ">
                    
                    <div className="getintouch flex flex-col  w-[50%] gap-5 box from-left" >
                        
                    <div className="text-xl text-blue-700 ">GET IN TOUCH</div>
                        <div className="title"><p className="dark:text-blue-900 font-bold text-3xl font-serif ">Seamless Communication, <p>Global Impact</p></p></div>
                        <div className="text-justify text-neutral-500 text-xl"><p >Your journey with Gopeeshwar Dairy starts with a single step. <br /> Reach out to us today, and let's create something wonderful together. </p></div>
                        <div className="address ">
                            <div className="flex gap-2 ">
                                <div className="text-4xl text-blue-900 "><FaLocationDot /></div>
                                <div className="text-[17px] text-justify text-neutral-500"><p>Gopeeshwar Dairy
                                Ganeshpur, Tembhurni, </p><p>Taluka- Jaffrabad District-Jalna, Maharashtra, 431208</p>
                                </div>
                                                                                 </div>
                            <div className="flex gap-2 mt-[2%]">
                                <div className="text-4xl text-blue-900 "><MdEmail />
                                </div>
                                <div className="text-[17px]text-justify text-neutral-500">gopeeshwarmilk@gmail.com
                                </div>
                            </div>
                            <div className="flex gap-2 mt-[2%]">
                                <div className="text-4xl text-blue-900 "><FaPhoneAlt /></div>
                                <div className="text-[17px] text-justify text-neutral-500">+91 1234567890</div>
                            </div>
                        </div>
                     
                    </div>  
                    
                    <div className="w-[40%] h-[600px] w-[600px]
                    bg-blue-800 ml-[5%] rounded-3xl flex flex-col p-[3%] box from-right" >
                        <form action="" className="text-xl">
                            <div className="text-center text-white text-3xl font-serif "><h3 >Send us a Message</h3></div>

                            <div className="m-[2%] ">
                                <label className= 'text-white' htmlFor="">Name</label><br /><input type="text" placeholder="Name" className="w-full rounded-sm  h-[35px]  shadow-xl border-blue " /></div>
                                
                           
                            <div className="m-[2%] ">
                                <label className= 'text-white' htmlFor="">Email</label><br /><input type="text" placeholder="Email" className="w-full rounded-sm  h-[35px]  shadow-xl border-blue" /></div>
                                
                          
                            <div className="m-[2%] ">
                                <label className= 'text-white' htmlFor="">Phone No.</label><br /><input type="text" placeholder="Phone No." className="w-full rounded-sm  h-[35px]  shadow-xl border-blue" />
                                
                            </div>
                            <div className="m-[2%]">
                            <label className= 'text-white'  htmlFor="">Message</label><br />
                                <textarea name="" placeholder="Message" rows="5" cols="40" className="w-full rounded-sm  shadow-xl border-blue"></textarea>
                            </div>
                            {/* <div className="button-field m-[5%]">
                                <button type="submit" className="btn form-btn h-[20%] ">
                                Send Message
                               
                                </button>
                            </div> */}
                            
                            <div class="buttons">
                                <button class="blob-btn">
                                    Send Message
                                    <span class="blob-btn__inner">
                                    <span class="blob-btn__blobs">
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                    </span>
                                    </span>
                                </button>
                                <br/>

                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                                    <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                    </filter>
                                </defs>
                                </svg>
                            </div>
                        </form> 
                    </div>

                    
                
                    
                </div>
                
            </div>
        </div>
    );
}
export default Contact;