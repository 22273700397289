import Abouthero from '../component/Abouthero'
// import milk3 from '../assets/milkbg.png'
import milkcow from '../assets/cow6.jpg'
// import milkpitcher from '../assets/milkpitcher.jpg'
import './About.css';
import { FaCircleArrowRight } from "react-icons/fa6";
function About(){
    return(
        
             
        <div className="flex flex-col overflow-hidden ">
                <div>
                <Abouthero/>
                </div>
                <div className="flex  w-full h-[600px] mx-auto justify-center relative ">

                    <div className='flex w-[50%] '>
                        <div className='aboutcontent bg-cyan-100 w-[80%]  ml-[15%]'  >
                           <div className='felx felx-col m-[5%]'>
                                <div className='font-nova-flat text-orange-400 text-xl'>
                                    Why Choose us
                                </div>
                                <div className='mt-[3%]' ><p className='text-blue-800 font-semibold text-5xl'>Unleash the Magic of <br /> Farm Crafted Dairy</p>
                                 
                                </div>
                                <div className='text-neutral-500 text-xl mt-[3%]'>
                                Curabitur faucibus orci a condimentum pretium. Pellentesque vel libero feugiat nibh cursus consequat vel eu lectus.
                                </div>
                                <div className='flex flex-col mt-[7%] gap-4'>
                                    <div className='flex gap-2'>
                                        <div className='text-orange-400'><FaCircleArrowRight /></div>
                                        <div className='text-blue-900 text-[20px] font-bold font-roboto'>100% Naturally 
                                            <div className='text-[15px] text-neutral-500 ' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, non.</div>
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div className='text-orange-400'><FaCircleArrowRight /></div>
                                        <div className='text-blue-900 text-[20px] font-bold font-roboto'>Best Quality Products
                                        <div className='text-[15px] text-neutral-500' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, non.</div>
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div  className='text-orange-400'>< FaCircleArrowRight /></div>
                                        <div className='text-blue-900 text-[20px] font-bold font-roboto'>Expert Workers
                                        <div className='text-[15px] text-neutral-500' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, non.</div>
                                        </div>
                                    </div>
                                    
                                </div> 
                           </div>
                        </div>
                    </div>
                    <div className='w-[50%]'>
                        <img src={milkcow} alt="" className='rounded-lg w-full  mb-[15%]'/>
                    </div>
                </div>
        </div>
    );
}
export default About;