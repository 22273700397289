
import React from 'react';
import cowimg from '../assets/coww.jpg';
import milkwave from '../assets/wave-removebg-preview.png'
import './Contacthero.css';
import logo from '../assets/Logo-removebg-preview (1).png'
function Abouthero(){
    
    return(
        
        <div className="flex flex-col">
            <div className='relative  '>
                <div className='absolute  z-1 w-[30%] left-0 bg-gradient-to-r from-blue-600 to-indigo-400'></div>
                    <img src={cowimg} alt="heroimg"  className=' h-[500px] w-full mb-[10%]  '/>
                 <img src={milkwave} alt="splashmilk" className='absolute w-[100%] h-[500px] m' style={{top:'59%',left:'50%',transform:'translate(-50%,-50%)'}}/>  
                 <img src={logo} alt="logo" className='logo absolute top-[40%] right-[5%] w-[15%] drop-shadow-2xl hover:drop-shadow-xl ' />
                 <div className='flex flex-col '> <p className='topp absolute text-4xl font-[500] text-blue-900 bottom-[18%] right-0 drop-shadow-md font-nova-flat' >   Discover the purity. Enjoy the freshness.<br/>Taste the difference with Gopeeshwar Dairy </p>  
            
                 </div>   
                
            </div>
           

        </div>
    );
}
export default Abouthero;