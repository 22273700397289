// import '../component/Hero.css';
import React from 'react';
import heimg from '../assets/himg.webp';
import milksplash from '../assets/wave-removebg-preview.png'
import './Contacthero.css';
// import milkimg from '../assets/hero_milk_bottle.jpeg';
// import taglinee from '../assets/taglinee-removebg-preview.png'
function Contacthero(){
    return(
        <div className="">
            <div className='relative '>
                    <img src={heimg} alt="heroimg"  className=' h-[600px] w-full '/>
                 <img src={milksplash} alt="splashmilk" className='absolute w-[100%] h-[500px] m' style={{top:'63%',left:'50%',transform:'translate(-50%,-50%)'}}/>  
                 <div className='flex flex-col '> <p className='top absolute text-4xl font-[500] text-blue-900 bottom-[20%] left-[15%] drop-shadow-md font-nova-flat' >Contact us</p>  
            
                 <p className='bottom absolute text-3xl font-medium text-sky-500 bottom-[5%] left-[8%] drop-shadow-md font-nova-flat '>Start the conversation to established <br />good relationship and business </p>
                 </div>   
            </div>
           

        </div>
    );
}
export default Contacthero;