// import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import logo from '../assets/Logo-removebg-preview (1).png';


import { Link } from 'react-router-dom'; 
const Navbar = () =>{

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };
    return(
        <header className="sticky top-0 z-50  backdrop-blur-lg  border-neutral-700/80 shadow-md w-full ">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center ">
          <img src={logo} alt="Gopeshwar Dairy Logo" className="h-12 mr-4  " />
        </div>
        <nav className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block`}>
          <ul className="flex flex-col md:flex-row md:space-x-6">
            <li className=' group text-black font-semibold px-3 py-2 rounded hover:bg-sky-200 hover:text-blue-900 transition duration-300  cursor-pointer transition-background-color-sky-500'>
              <Link className='' to ='/'>Home</Link>   </li>
           
            <li className='text-black font-semibold px-3 py-2 rounded hover:bg-sky-200 hover:text-blue-900 transition duration-300  cursor-pointer transition-background-color-sky-500'><Link className='' to ='/all-product'>All Products</Link></li>
            <li className='text-black font-semibold px-3 py-2 rounded hover:bg-sky-200 hover:text-blue-900 transition duration-300  cursor-pointer transition-background-color-sky-500'><Link className='' to ='/about'>About Us</Link></li>
            <li className='text-black font-semibold px-3 py-2 rounded hover:bg-sky-200 hover:text-blue-900 transition duration-300  cursor-pointer transition-background-color-sky-500'><Link className='' to ='/contact'>Contact Us</Link></li>
          </ul>
           {/* <ul className="flex flex-col md:flex-row md:space-x-6">
            <li><Link to="/" className="milk-hover text-black font-semibold px-3 py-2 rounded">Home</Link></li>
            <li><Link to="/all-products" className="milk-hover text-black font-semibold px-3 py-2 rounded">All Products</Link></li>
            <li><Link to="/about-us" className="milk-hover text-black font-semibold px-3 py-2 rounded">About Us</Link></li>
            <li><Link to="/contact-us" className="milk-hover text-black font-semibold px-3 py-2 rounded">Contact Us</Link></li>
          </ul> */}
        </nav>
        <div className="md:hidden" onClick={handleClick}>
          <div className="space-y-2">
            <div className="w-8 h-1 bg-black"></div>
            <div className="w-8 h-1 bg-black"></div>
            <div className="w-8 h-1 bg-black"></div>
          </div>
        </div>
      </div>
    </header>
    );
}

export default Navbar;