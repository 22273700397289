
function Card({product}){
    
    
  
    return(
      
    <div className='flex gap-4 justify-between m-[5%] cursor-pointer bg-white justify-between hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05] rounded-lg'> 
    <div className='flex flex-col justify-center items-center gap-2'>
    <img src={product.image} alt="product" className='h-[300px] w-[300px]'/>
    <p className='text-lg font-semibold'>{product.title}</p>
    {/* <p className='text-lg font-semibold'>Price: ₹ {product.price}</p> */}
    </div>
    </div>

    );
}
export default Card;