import { products } from '../data/productdata'
import Card from '../component/Card'
function Products(){
    
    return(
        <div className="flex flex-wrap justify-center">
        
      {products.map((product, index) => (
        <Card key={index} product={product} />
      ))}
    </div>
    );
}
export default Products;