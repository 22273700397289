// import '../component/Hero.css';
import React from 'react';
import heroimg from '../assets/heroimg.jpg';
import productimg from '../assets/allproducts.png'
// import milkimg from '../assets/hero_milk_bottle.jpeg';
// import taglinee from '../assets/taglinee-removebg-preview.png'
function Hero(){
    return(
        <div className="">
            <div className=''>
                    <img src={heroimg} alt="heroimg"  className='h-[600px] w-full relative  '/>
                    <img src={productimg} alt="heroimg1" className='absolute top-[30%] right-[30%] h-[70%]'/>
                    {/* <img src={taglinee} alt="tagline" /> */}
            </div>
        </div>
    );
}
export default Hero;