
import picture1 from '../assets/Picture1-removebg-preview.png';
import picture2 from '../assets/Picture2-removebg-preview.png';
import picture3 from '../assets/Picture3-removebg-preview.png';
import picture4 from '../assets/Picture4-removebg-preview.png';
import picture5 from '../assets/Picture5-removebg-preview.png';
import picture6 from '../assets/Picture6-removebg-preview.png';
import picture7 from '../assets/Picture7-removebg-preview.png';
import picture8 from '../assets/Picture8-removebg-preview.png';
import picture9 from '../assets/Picture9-removebg-preview.png';
import picture10 from '../assets/Picture10-removebg-preview.png';
import picture11 from '../assets/Malai_Pedha-removebg-preview.png';
import picture12 from '../assets/Malai_Kulfi-removebg-preview.png';
export const products = [
    {
        'title':'Shrikhand',
      
        'image':picture1
    },
    {
        'title':'Amrakhand',
        
        'image':picture2
    },
    {
        'title':'Malai Lassi',
     
        'image':picture3
    },

    {
        'title':'Mango Lassi',
       
        'image':picture4
    },
    {
        'title':'Malai Dahi',
       
        'image':picture5
    },
    {
        'title':'Basundi',
      
        'image':picture6
    },
    {
        'title':'Pure Ghee',
       
        'image':picture7
    },
    {
        'title':'Malai Paneer',
        
        'image':picture8
    },
    {
        'title':'Butter Milk',
       
        'image':picture9
    },
    {
        'title':'Mava Kulfi',
       
        'image':picture10
    },
    {
        'title':'Malai Pedha',
       
        'image':picture11
    },
    {
        'title':'Malai Kulfi',
       
        'image':picture12
    },
]