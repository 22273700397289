import Hero from '../component/Hero'
import OurProducts from '../component/OurProducts'
function Home(){
    return(
        <div>
            <Hero/>
            <OurProducts/>
        </div>
    );
}
export default Home; 