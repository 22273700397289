// import ourproduct1 from '../assets/our-goodness-8.png'
import ourproduct1 from '../assets/Picture1-removebg-preview.png'
import ourproduct2 from '../assets/Picture5-removebg-preview.png'
import ourproduct3 from '../assets/Picture9-removebg-preview.png'
function OurProduct(){
    return(
        // <div className="w-full  m-[2%] ">
            
        //         <div className="flex flex-col gap-10 ">
        //                 <div>
        //                     <p className="text-green-800 font-semibold text-4xl text-center ">Our Goodness </p>
        //                     <p className="text-neutral-400  text-2xl text-center ">Comes in many types!</p>
        //                 </div>
        //                 <div className="flex w-full justify-center items-center gap-10 ">
        //                      <div className="flex flex-col items-center justify-center w-[40%] border bg-gradient-to-b from-cyan-100 to-blue-50 hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05] py-6">
        //                         <div>
        //                         <img src={ourproduct1} alt="" />
        //                         </div>
        //                         <div>
        //                             <p>UHT Milk</p> 
        //                         </div>
                                
        //                      </div>

        //                      <div className="flex flex-col items-center justify-center w-[40%] border hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-6 bg-gradient-to-b from-sky-200 to-indigo-50">
        //                         <div>
        //                         <img src={ourproduct1} alt="" />
        //                         </div>
        //                         <div>
        //                             <p>UHT Milk</p>
        //                         </div>
                                
                                
        //                      </div>

        //                      <div className="flex flex-col items-center justify-center w-[40%] border hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-6 bg-gradient-to-b from-sky-200 to-indigo-50">
        //                         <div>
        //                         <img src={ourproduct1} alt="" />
        //                         </div>
        //                         <div>
        //                             <p>UHT Milk</p>
        //                         </div>
                                
                                
        //                      </div>
        //                      <div className="flex flex-col items-center justify-center w-[40%] border hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-6 bg-gradient-to-b from-sky-200 to-indigo-50">
        //                         <div>
        //                         <img src={ourproduct1} alt="" />
        //                         </div>
        //                         <div>
        //                             <p>UHT Milk</p>
        //                         </div>
                                
                                
        //                      </div>
                       
        //                 </div>


                        
        //         </div>

        // </div>
        <div className='flex flex-col'>
            <div className='text-center text-green-800 text-4xl font-semibold mt-[2%]'>Our Goodness</div>
            <div className='text-neutral-500 text-center text-xl'>Comes in many types!</div>

        <div className='flex flex-wrap justify-center '>
                    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg">
                        <img className="w-full" src={ourproduct1} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Shrikhand</div>
                        </div>
                    </div>

                    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg ">
                        <img className="w-full" src={ourproduct2} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Curd</div>
                        </div>
                    </div>
                    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg">
                        <img className="w-full" src={ourproduct3} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Butter Milk</div>
                        </div>
                    </div> 
                    {/* <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg">
                        <img className="w-full" src={ourproduct1} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Lassi</div>
                        </div>
                    </div>  
                    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg">
                        <img className="w-full" src={ourproduct1} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Paneer</div>
                        </div>
                    </div>
                    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col items-center hover:scale-110 transition duration-300 ease-in  shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:scale-[1.0.3] md:hover:scale-[1.05]  py-4 bg-gradient-to-r from-cyan-100 to-blue-50 cursor-pointer rounded-lg">
                        <img className="w-full" src={ourproduct1} alt="UHT Milk" />
                        <div className="px-6 py-4">
                            <div className="text-green-900 text-xl mb-2">Ice-Cream</div>
                        </div>
                    </div> */}
        </div>

        </div> 
    );
}
export default OurProduct;