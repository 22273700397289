import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './component/Navbar';
import {Routes , Route } from "react-router-dom" 
import Home from './pages/Home'
import AllProducts from './pages/AllProducts'
import About from './pages/About'
import Contact from './pages/Contact'
import NoPage from './pages/NoPage'
import Footer from './component/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden ">

      <main className='flex-1'> 
        <Navbar />
      {/* <Routes>
        
          <Route index element={<Home />} />
          <Route path="all-products" element={<AllProducts />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<NoPage />} />
      
      </Routes> */}
       <Routes> 
            <Route path="/" element={<Home/> } /> 
            <Route path="/all-product" element={<AllProducts/> } /> 
            <Route path="/about" element={<About/> } /> 
            <Route path="/contact" element={<Contact/> } /> 
            <Route path="*" element={<NoPage/> } /> 
       </Routes>
      </main>
      
       <div className='footer'><Footer/> </div>
       
    </div>
  
  );
}

export default App;
