import Products from "../component/Products";
function AllProducts(){
    return(
        <div className="flex flex-col m-[2%]">
            <div className='font-nova-flat text-3xl text-center font-bold text-green-800 '>
                All Products
            </div>
            <div>
              <Products/>
            </div>
           
        </div>
    );
}
export default AllProducts; 